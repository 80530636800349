import { scrollTo } from './utils'

export function initAccordions(selector = '.panel .panel-collapse') {
  $(selector).each((i, el) => {
    $(el).on('shown.bs.collapse', (e) => {
      const { top } = $(e.currentTarget).closest('.panel').offset()
      let offset = 0

      if ($('html').hasClass('cms-toolbar-expanded')) {
        offset = 45
      }

      scrollTo(top - offset)
    })
  })
}

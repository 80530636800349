import $ from 'jquery'

export function initSearchFiltersForm() {
  const form = $('.js-search-filters-form')
  const searchContainer = $('.js-search-container')

  form.on('change', ':radio, :checkbox', () => form.submit())

  form.on('submit', () => {
    searchContainer.addClass('loading')
  })
}

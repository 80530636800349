export function showTabFromHash() {
  const hash = window.location.hash
  let content = $()

  try {
    content = $(`${hash}, a[name=${hash.slice(1)}]`)
  } catch (e) {} // eslint-disable-line

  const pane = content.closest('.tab-pane')

  if (!pane.length) {
    return
  }

  const paneId = pane.attr('id')

  $(`[href='#${paneId}']`).tab('show')
}

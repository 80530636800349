import $ from 'jquery'

function initPostsByAuthorLink() {
  const link = $('.js-blog-filter-expand-link')

  link.on('click', (e) => {
    e.preventDefault()
    e.stopPropagation()

    $(e.target).hide().prev('ul').children('li').show()
  })
}

export function initBlog() {
  initPostsByAuthorLink()
}

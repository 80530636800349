import $ from 'jquery'
import Cookies from 'js-cookie'

class ModalBox {
  constructor(element) {
    this.cookieName = `closed-modal-box-${element.attr('id')}`
    this.expires = element.data('expires') ? parseInt(element.data('expires'), 10) : 7
    this._setupUI(element)
    this._setupEvents()
  }

  _setupUI(element) {
    this.ui = {
      modal: element,
      close: element.find('.js-modal-box-close'),
      window: $(window),
      cookiePolicy: $('.js-cookie-policy')
    }
  }

  _setupEvents() {
    this.ui.window.on('load', () => {
      if (!this._checkIfCookieExists()) {
        this.open()
      }
    })

    this.ui.close.on('click', () => {
      this.close()
    })
  }

  _checkIfCookieExists() {
    return Cookies.get(this.cookieName)
  }

  _setCookie() {
    Cookies.set(this.cookieName, 'true', {
      expires: this.expires,
      path: ''
    })
  }

  open() {
    if (this.ui.cookiePolicy.is(':visible')) {
      this.ui.modal.css('margin-bottom', this.ui.cookiePolicy.outerHeight())
    }

    this.ui.modal.fadeIn('fast')
  }

  close() {
    this.ui.modal.fadeOut('fast', () => {
      this._setCookie()
    })
  }
}

export function initModalBoxes(selector = '.js-modal-box') {
  $(selector).each((i, el) => new ModalBox($(el)))
}

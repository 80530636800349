import $ from 'jquery';
import 'jquery-validation';
import 'jquery-validation/dist/additional-methods';

const LANGUAGES = {
    "en": "en",
    "en-au": "en",
    "en-nz": "en",
    "en-gb": "en",
    "en-ca": "en",
    "en-za": "en",
    "pt-br": "pt_BR",
    "fr": "fr",
    "fr-ca": "fr",
    "zh-hans": "zh",
    "zh-hant": "zh_TW",
    "de": "de",
    "de-at": "de",
    "it": "it",
    "ja": "ja",
    "es": "es",
    "es-xl": "es",
    "pl": "pl",
    "ru": "ru",
    "sk": "sk",
    "ch-de": "de",
    "ch-fr": "fr",
    "cs": "cs",
    "cs-cz": "cs",
    "da": "da",
    "fi": "fi",
    "nb": "en",
    "sv": "sv",
    "nl": "nl"
};

const addRadioOrCheckboxSelectValidity = function(inputs) {
    if (inputs.length > 1) {
        inputs.rules('add', {
            require_from_group: [1, inputs],
        });
    }
};

function initCustomValidationRules(form) {
    const requiredFields = form.find('.form-required');
    requiredFields.toArray().forEach(el => {
        const group = $(el);

        const maybeRadios = group.find(':radio');

        if (maybeRadios.length) {
            return addRadioOrCheckboxSelectValidity(maybeRadios);
        }

        const maybeCheckboxes = group.find(':checkbox');

        if (maybeCheckboxes.length) {
            return addRadioOrCheckboxSelectValidity(maybeCheckboxes);
        }
    });
}

export default function initLocale() {
    const locale = $('html').attr('lang');

    if (LANGUAGES[locale] === 'en') {
        return;
    }
}

window.IDEXX = window.IDEXX || {};
window.IDEXX.initCustomValidationRules = initCustomValidationRules;

import simpleParallax from 'simple-parallax-js'

function initAllParallax() {
  const parallaxElements = document.querySelectorAll('[data-parallax]')
  new simpleParallax(parallaxElements, {
    scale: 1.5
  })
}

export function initParallax() {
  initAllParallax()
}
